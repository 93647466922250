.loading__screen{
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0px;
    padding-top: 150px;
    /* align-items: center; */
    background: transparent;
    /* height: 100vh; */
}

.spinner {
    position: relative;
    width: 90px;/*45*/
    height: 90px;/*45*/
    margin: 0 auto;
    margin-top: 10px;
  }
  
  .bubble-1,
  .bubble-2 {
    position: absolute;
    top: 0;
    width: 45px;/*25*/
    height: 45px;/*25*/
    border-radius: 100%;
    background-color:var(--greyish-color);
  }
  .bubble-2 {
    top: auto;
    bottom: 0;
    background-color: var(--reddish-color);
}
  
.spinner {
    /* animation: loadingI 2s linear infinite; */

    animation: loadingI 2s linear infinite;
  }
.bubble-1,
.bubble-2{
  animation: bounce 2s ease-in-out infinite;

    /* animation: bounce 0.2s ease-in-out infinite; */
}
.bubble-2 {
    animation-delay: -1s;
  }

@keyframes bounce {
    0%,
    100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
  }


@keyframes loadingI {
    100% {
      transform: rotate(360deg);
    }
  }

  /**/
  /* .salwan{
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    height: 100vh;
    
  }
  .letter-holder {
    padding: 16px;
  } */

  /* .letter {
    float: left;
     font-size: 4rem;
    color: #777;
  }

  .letter {
    animation-name: loadingF;
    animation-duration: 1.6s;
    animation-iteration-count: infinite;
    animation-direction: linear;
  } */
/* 
  .l-1 {
    animation-delay: 0.48s;
  }
  .l-2 {
    animation-delay: 0.6s;
  }
  .l-3 {
    animation-delay: 0.72s;
  }
  .l-4 {
    animation-delay: 0.84s;
  }
  .l-5 {
    animation-delay: 0.96s;
  }
  .l-6 {
    animation-delay: 1.08s;
  } */
  /* .l-7 {
    animation-delay: 1.2s;
  } */
  /* .l-8 {
    animation-delay: 1.2s;
  }
  .l-9 {
    animation-delay: 1.32s;
  }
  .l-10 {
    animation-delay: 1.44s;
  } */
  

/* @keyframes loadingF {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  } */

  /*  ===============================================================================  */

  /* .cirle_spinner{
    border: 20px solid;
    border-color: #ad0207; 
    border-color: -webkit-linear-gradient(to right, #ffffff, #ad0207); 
    border-color: linear-gradient(to right, #ffffff, #ad0207); 
    animation: rotate 1s infinite;
  }

  @keyframes rotate {
   
    100% {
      transform: rotate(360);
    }
  } */
