.showcase__brands{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 2rem;
}
.showcase__brandItem{
    margin: 0.5rem;
    height: 10rem;
    padding: 0.5rem;
    border-radius: 2px;
}
.showcase__brandItem:hover{
    transform: scale(1.1);
    transition: transform 0.3s;
}

@media (max-width: 950px){
    .showcase__brands{
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (max-width: 450px){
    .showcase__brands{
        grid-template-columns: repeat(1, 1fr);
       
    }
    /* .showcase__brandItem{
        margin:1;
    } */
}