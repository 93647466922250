.hamburger__menu,
.hamburger__menu__admin{
    display: none;
}

@media (max-width: 960px){
    .hamburger__menu{
        display: block;
        font-size: 1.5rem;
        color:  var(--reddish-color);
    }
}
@media (max-width: 850px){
    .hamburger__menu__admin{
        display: block; 
        font-size: 1.5rem;
        color:  var(--greyish-color); 
    }
}

