.horizontal__linebreak {
    width: 100%;
    height: 0.8rem;
    border-bottom:1px solid  var(--greyish-color);
    text-align: center;
    margin: 1rem auto;
  }
  
  .content__in__linebreak {
    font-size: 1.2rem;
    background-color: white;
    padding: 0 10px;
  }
  .content__in__linebreak a {
    text-decoration: none;
    color: var(--greyish-color);
    font-weight: bold;
  }
  .content__in__linebreak a:hover {
    color: black;
  }


  @media (max-width: 500px){
    .content__in__linebreak {
        font-size: 1rem;
        background-color: white;
        padding: 0 10px;
      }
  }