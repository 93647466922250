.salwan__navbar{
    width: 100%;
    background-color: #ffffff;
    margin: 0px auto;
    text-align: center;
}
.salwan__navbar__container{
    max-width: 1300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 1.5rem; 
    margin: 0px auto;
}

.salwan__navbar__logo{
    width: 180px;
}

.brand__icon:hover{
color: var(--reddish-color);
}
.salwan__navbar__links {
    display: flex;
    list-style: none;
    align-items: center;
    justify-content: space-between;
    /* border: 1px solid red; */
    padding: 0px;
    width: 40%;
    /* margin: 0px; */
}

/*STYLING ALL OF THE lis IN navbar__links */
/* .salwan__navbar__links >li {
    padding-left: 1.5rem;
} */
.salwan__navbar__links > li > a,  
.salwan__navbar__links > li > p{
    text-decoration: none;
    color: var(--greyish-color);
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-top: 1.3rem;
    font-weight: bold;
    padding-bottom: 0.6rem;
    transition: 0.4s;
    border-bottom: 4px solid white;
}

.salwan__navbar__links > li > a:hover,
.salwan__navbar__links > li > p:hover{
    border-bottom:4px solid var(--reddish-color);
}

#cart__link:hover{
    border-bottom: 4px solid white;
    color: var(--reddish-color);
}


.nav__cart__container a{
  text-decoration: none;
} 

#cart__link > span{
  color: var(--greyish-color);
  text-decoration: none;
  font-size: 1.1rem;
  transition: 0.2s;
}
#cart__link > span:hover{
    color: var(--reddish-color);
  }
#currently__cart{
    color: var(--reddish-color);
    background-color: white;
}
/* .hamburger__menu{
    display: none;
} */
/* RESPONSIVE DESIGN */
@media (max-width: 960px){
    .salwan__navbar__links,
    .nav__cart__container {
        display: none;
    }
    .hamburger__menu{
        display: block;
        font-size: 1.5rem;
        color:  var(--reddish-color);

    }
    .salwan__navbar__logo{
        width: 140px;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem; 
    }
}

@media (max-width: 500px){
    .salwan__navbar__logo{
        width: 120px;
    }
}