.App{
    scroll-behavior: smooth;
    height: 100vh;
    /* display: flex;
    flex-direction: column;
    overflow-x: hidden;
    flex-shrink: 0; */
}

.content {
  /* scroll-behavior: smooth; */

  min-height: 100px;
    flex: 1 0 auto;
  }

  .whatsApp__icon__container{
    position: fixed;
    z-index: 2000;
    right:10px;
    bottom: 80px;

  }
  .whatsApp__icon{
      width: 80px;
      height: 80px;
  }

@media (max-width: 500px){
  .whatsApp__icon{
    width: 50px;
    height: 50px;
}
.whatsApp__icon__container{
  position: fixed;
  z-index: 2000;
  right:15px;
  bottom: 80px;

}
}