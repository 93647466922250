.homescreen{
    display: flex;
    flex-direction: column;

}


.homescreen__section{
    margin: 4rem 1.5rem 1.5rem;
}

.home__section__header{
    font-size: 1.8rem;
    color: var(--greyish-color);
    background-color:rgb(148, 21, 12) ;
    /* background-color: var(--reddish-color); */
    /* background: linear-gradient(180deg, rgb(235, 64, 52), rgb(0, 0, 0)); */
    border-radius: 5px;
    text-align: center;
    color: white;
    padding: 0.5rem;
}


/*=================== MOBILE SCREENS =====================*/
@media (max-width: 1232px) {
  
  
  }
  
 @media (max-width: 750px) {
  
 
  }
  @media (max-width: 850px) {


  
  }
 

  @media (max-width: 500px){
   
    .home__section__header{
        font-size: 1.4rem;
        text-align: center;
        font-weight: bold;
    }
    .homescreen__section{
        margin: 3rem 0rem 1.5rem;
    }
  }

  @media (max-width: 400px){
   
  }
  @media (max-width: 320px){

}
