.slideItem__content{
    position: absolute;
    left:0px;
    top:0;
    width: 100%;
    height: 100%;
    background:rgba(0, 0, 0, 0.15);
}
.slideItem__inner{
    position: absolute;
    display: flex; 
    flex-direction: column;
    bottom: 100px;
    left: 100px;
}
.slideItem__inner__content{
    display: flex;
    flex-direction: column;
    color: white;
    /* background: rgba(0, 0, 0, 0.4); */
    padding: 1rem;
    max-width: 400px;
    text-shadow:2px 0px 8px rgba(0, 0, 0,0.5);
    visibility: hidden;
    animation:  animateCaption  linear forwards;
    /* animation-delay: 0.2s; */
    animation-duration: 0.5s;
}

.hero__header{
    font-size: 1.9rem;
    color:rgba(255, 255, 255, 0.4)
}
.hero__caption{
    font-size: 2rem;
    font-weight: bold;
    text-shadow: -5px 1px 8px rgba(0,0,0,0.76);

}
.call2action{
    /* z-index: 10; */
    margin-top: 2rem;
    align-self: flex-end;
    font-weight: bold;
    border:4px solid var(--reddish-color);
    padding: 10px 20px;
    color: var(--reddish-color);
    cursor: pointer;
    transition: 0.5s;
    letter-spacing: 2px;
    background: white;
    visibility: hidden;
    animation:  animateC2A  linear forwards;
    animation-delay: 0.2s;
    animation-duration: 0.5s;
}
.call2action:focus{
    outline: none;
} 
.call2action:hover{
    letter-spacing: 4px;
}
.hide__Caption{
    display:none;
}


@media (max-width: 750px) {
.call2action{
      padding: 7px 14px;
      letter-spacing: 1px;
}
.call2action:hover{
      letter-spacing: 2px;
}

}

@media (max-width: 500px){
   
    .slideItem__inner__content{
        padding: 0.5rem;
    }
    .hero__header{
        font-size: 1.5rem;
    }
    .hero__caption{
        font-size: 1.2rem;
        font-weight: bold;
        /* text-shadow: -5px 1px 8px rgba(0,0,0,0.76); */
        text-shadow: -5px 1px 13px rgba(0,0,0,0.82)
        ;
    }
 .slideItem__inner{
     left:5%;
     right: 5%;
     margin-left: -0.5em;
 }
  .call2action{
  letter-spacing: normal;
  align-self: center;
  }
  .call2action:hover{
      letter-spacing: 1px;
  }
}

@media (max-width: 375px){

    .call2action{
      padding: 4px 7px;
      letter-spacing: none;
      position: static;
      align-self: center;
      margin-top: 20px;
      border: 1px solid var(--reddish-color);
      font-size: 0.9rem;
    
    }
}
    

@media (max-width: 320px){

.call2action{
  padding: 2px 5px;
  letter-spacing: none;
  position: static;
  align-self: center;
  margin-top: 20px;
  border: 1px solid var(--reddish-color);
  font-size: 0.8rem;

}
.hero__header{
    font-size: 1rem;
}
.hero__caption{
    font-size: 0.8rem;
}
}

@keyframes animateCaption{
    0%{
        opacity: 0;
        transform: translateX(-200px);
    }
    100% {
        visibility: visible;
        opacity: 1;
        transform: rotate(360deg);
      }
}

@keyframes animateC2A{
    0%{
        opacity: 0;
        transform: translateY(200px);
    }
    100% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
      }
}