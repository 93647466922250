.homescreen__service__section__background{
    width: 100%;
    height: 28.2rem;
    background-image: url("../../../../images/checkerPlate1.jpeg");
    /* background-color: rgba(); */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
}

.homescreen__service__section{
 width: 100%;
 height: 100%;
 padding-top: 1.5rem;
 background-color: rgb(0, 0, 0, 0.8);
}
.homescreen__service__section__header{
    text-align: center;
    color: #f4f4f4
}
.homescreen__service__section__header span{
    font-size: 2rem;
    font-weight: bold;
    border-bottom: 2px solid #f4f4f4;
}
.homescreen__service__section__header__content{
    
    height: 19.8rem;
    margin-top: 1rem;
    display: flex;
    justify-content: space-around;

}

.homescreen__service__section__header__content__left{
    display: flex;
    flex-direction: column;
}
.home__service__item__group{
    height: 3rem;
    display: flex;
    align-items: center;

    margin-bottom: 0.5rem;
}
.home__service__item__group:hover .home__Service__item{
    /* color: #e4abab; */
    color: var(--reddish-color);
    cursor:pointer;
    transform: scale(1.2);
    transition: 0.3s transform;
}
.home__service__icon{
    width: 3rem;
    height: 3rem;
    background-color: white;
    margin-right: 2rem;
    border-radius: 50%;
    background-image: url("../../../../images/serviceIcon.png");
    background-position: center;
    background-size: contain;
    transition: all 0.3s;
}
.home__service__icon:hover{
    opacity: 0.5;
    cursor: pointer;
}
.home__Service__item{
    font-weight: bold;
    color: #f4f4f4;
    letter-spacing: 1px;
    font-size: 1.5rem;
    text-transform: uppercase;
    /* text-shadow:2px 0px 8px rgba(0, 0, 0,0.5); */
    /* text-shadow: -5px 1px 13px rgba(216, 213, 213, 0.82) */


}
.homescreen__service__section__header__content__right{
    display: flex;
    align-items: center;
}

.home__get__quote{
    font-weight: bold;
    border:4px solid var(--reddish-color);
    padding: 10px 20px;
    color: var(--reddish-color);
    cursor: pointer;
    transition: 0.5s;
    letter-spacing: 2px;
    background: white;
}
.home__get__quote:focus{
    outline: none;
} 
.home__get__quote:hover{
    letter-spacing: 4px;
}

@media (max-width: 591px){
    .homescreen__service__section__header__content__right{
        display: none;
    }
}