:root {
  /* --greyish-color: #707070; */
  --greyish-color:  #424242;
  --reddish-color: #ad0207;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
/* body{
  font-family: Arial, Helvetica, sans-serif;
  background-color: #f4f4f4;
} */
/* PROPOSED */
body{ 
  font-family: Arial, Helvetica, sans-serif;
}
/* PROPOSED */

img {
  width: 100%;
  object-fit: contain;
}
.error__paragraph{
  text-align: center;
  font-size: 1.5rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  color: red;
}
/* APP TABLE STYLES */

.table__row th{
  text-align: center;
}

/*================ REACT MODAL STYLES ===================*/
.mymodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid #ccc;
  background: #fff;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 20px;
}
.myoverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ck-editor__editable_inline {
    min-height: 300px !important;
}

/*======PAGE COMPONENT TO BE TRANSFERED=======*/
.page__component__container{
  margin: 20px auto;
}
.pagination__btns{
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
}
.pagination__btns a {
  padding: 10px;
  margin: 3px;
  border:1px solid black;/*var(--greyish-color);*/
  border-radius: 5px;
  color: black;
  cursor: pointer;
}
.previous__btn:hover,
.next__btn:hover{
 color: black;
}

.pagination__active a{
  background: black;

} 
.pagination__active{
  color: white;
}
.hide_page_button{
  display: none;
}

@media (max-width: 500px) {
  .error__paragraph{
    font-size: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}