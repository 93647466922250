.showcase__vehicles{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 1rem;
}

.showcase__vehicles__item{
    margin: 0.5rem;
    height: 16rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: bold;
    align-items: center;
    text-decoration: none;
    color: var(--reddish-color);
    transition: 0.5s;
    padding: 0.5rem;
    border-radius: 2px;
}
.showcase__vehile__info{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.showcase__vehile__name{
    color: black;
}
.showcase__vehile__year{
    font-size: 0.9rem;
    color: var(--greyish-color);
}
.showcase__vehicles__item:hover{
    box-shadow: 1px 2px 12px 10px rgba(0,0,0,0.38);
    transform: scale(1.02);
}
.showcase__vehicles__item:hover div span{
    transform: scale(1.2);
    transform: translateY(-10);
    transition: 1s;
}

@media (max-width: 1100px){
    .showcase__vehicles{
        grid-template-columns: repeat(4, 1fr);
    }
}
@media (max-width: 830px){
    .showcase__vehicles{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 530px){
    .showcase__vehicles{
        grid-template-columns: repeat(2, 1fr);
    }
    .showcase__vehicles__item{
        padding: 1rem;
        font-size: 0.9rem;
    }
    .showcase__vehicle__year{
        font-size: 0.8rem;
    }
}
@media (max-width: 380px){
    .showcase__vehicles{
        grid-template-columns: repeat(1, 1fr);
    }
}