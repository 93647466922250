.sidedrawer{
    width: 70%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    top:0;
    left:0;
    z-index: 200;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;/*Ease out, starts fast and ends slow*/
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.sidedrawer.show{
  transform: translateX(0);
}
  .sidedrawer__links{
    display: flex;
    flex-direction: column;
    list-style: none;
} 

.sidedrawer__links > li {
    display: flex;
    align-items: center;
}

.sidedrawer__links > li > a ,
.sidedrawer__links > li > p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1; 
    padding: 1rem;
    text-decoration: none;
    color: var(--greyish-color);
    font-size: 1.6rem;
    cursor: pointer;
} 

.sidedrawer__links > li > a:hover,
.sidedrawer__links > li > p:hover{
    background: var(--greyish-color); 
    color: white; /* offwhite*/
}


.sidedrawer__links > li > a > span {
    display: flex;
    align-items: center;
    margin-left: 8px;
    background-color: white;
    color:var(--greyish-color);
}
#currently__side__cart{
    color: white;
    background-color: var(--greyish-color);
}


.sidedrawer__links > li > a:hover span{
     background: var(--greyish-color);
     color: white;
}

@media(min-width: 960px){
    .sidedrawer{
        display: none;
    }
}
