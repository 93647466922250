#slider__left__button{
color: white;
font-size: 1.5rem;
font-weight: bold;
position: absolute;
left: 1rem;
bottom: 50%;
margin-top: -50px;
}

#slider__right__button{
color: white;
font-size: 1.5rem;
font-weight: bold;
position: absolute;
right: 1rem;
bottom: 50%;
margin-top: -50px;
}