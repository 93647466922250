.showcase__categories{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 1rem;
    align-items: flex-start;
}
.showcase__category__item{
    margin: 0.5rem;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 1.1rem;
    font-weight: bold;
    align-items: center;
    text-decoration: none;
    color: var(--reddish-color);
    transition: 0.5s;
    padding: 0.5rem;
    border-radius: 2px;
    border: 2px solid rgb(29, 28, 28);
    background: rgba(249, 249, 249, 0.9);
}
.img__container{
    position: relative;
    /* height: 100px; */
}
.img__container div{
 position: absolute;
 height: 100%;
 background-color: rgba(60, 77, 97, 0.38);
} 
.showcase__category__item div{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
}
.showcase__category__count{
    color: var(--greyish-color);
    font-size: 0.9rem;
}

.showcase__category__item:hover{
    box-shadow: 1px 2px 12px 10px rgba(0,0,0,0.38);
    transform: scale(1.02);
    color: var(--reddish-color);
}
.showcase__category__item:hover .showcase__category__count{
    color: var(--greyish-color);
}
.showcase__category__item:hover div p{
    transform: scale(1.2);
    transform: translateY(-10);
    transition: 1s;
}

@media (max-width: 1100px){
    .showcase__categories{
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 830px){
    .showcase__categories{
        grid-template-columns: repeat(3, 1fr);
    }
}
@media (max-width: 530px){
    .showcase__categories{
        grid-template-columns: repeat(2, 1fr);
    }
    .showcase__category__item{
        padding: 1rem;
        font-size: 0.9rem;
    }
    .showcase__category__count{
        font-size: 0.8rem;
    }
}

@media (max-width: 380px){
    .showcase__categories{
        grid-template-columns: repeat(1, 1fr);
    }
   
}